<template>
  <div class="profile-page">
    <section class="section section-skew">
      <div class="container">
        <div class="row text-center">
          <div
            class="col-lg-6 offset-lg-3 col-sm-6 offset-sm-3 col-12 p-3 error-main"
          >
            <div class="row">
              <div class="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                <h1 class="m-0">404</h1>
                <h6>Page not found</h6>
                <base-button
                  tag="router-link"
                  :to="{ name: 'landing' }"
                  type="primary"
                >
                  Go Back Home
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
